<template>
    <div class="searchResult" :key="new Date().getTime()">
        <el-page-header @back="backPrivious" content="企业搜索结果"></el-page-header>

        <SearchBox @handleSearchBox="handleSearchBox"></SearchBox>

        <div class="breadcrumb">
            <el-breadcrumb class="f-c-e" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">
                    <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
                    <span> 当前位置： 首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>企业搜索结果</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <!-- 提示语 -->
        <div v-if="tipText && typeName == 'firm'" class="tips mt-30">搜索到 {{ total }} 条{{ '与 “' + tipText + '”' }} 相关的企业</div>
        <div v-else-if="searchList.length && typeName == 'address'" class="tips mt-30">找到以下商家：</div>
        <!-- 列表-->
        <div v-if="searchList.length">
            <!-- <ProductCard :list="searchList"></ProductCard> -->
            <!-- 企业列表 -->
            <div class="f-w firmList">
                <div class="item f-fr-jc" v-for="(item, index) in searchList" :key="index"
                    @click="toEnterprise(item.enterpriseId)">
                    <img :src="item.eimage1" alt="">
                    <div class="name">{{ item.enterpriseName }}</div>
                    <div class="city">{{ item.city }} {{ item.address }}</div>
                </div>
            </div>
            <div v-show="(searchList && searchList.length != 0)" class="pagination flex">
                <el-pagination background :style="{ margin: '50px' }" :page-sizes="[10, 20, 30, 40, 50]"
                    :layout="' prev, pager, next,jumper'" :current-page.sync="currentPage"
                    :page-size.sync="pageSize" :total="total" @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"></el-pagination>
            </div>
        </div>
        <el-empty v-else description="暂无相关企业！"></el-empty>
        <DialogLogin :loginBoxVisible="loginBoxVisible" @CloseLoginBox="CloseLoginBox"></DialogLogin>
        <!-- 职业 -->
        <DialogStanding :jodBoxVisible="jodBoxVisible" @CloseJobBox="CloseJobBox"></DialogStanding>
    </div>
</template>
<script>

import SearchBox from '@/components/SearchBox.vue';
export default {
    name: 'searchFirm',
    inject: ['reload'],
    components: {
        SearchBox,
        // ImageUpload: () => import(/* webpackChunkName: "ImageUpload" */ '@/components/ImageUpload.vue')
    },
    data() {
        return {
            searchList: [],
            enterpriseName: '',
            address: '',
            city: '',
            queryType: '',
            loginBoxVisible: false,  // 登录弹窗
            jodBoxVisible: false,  // 职业弹窗
            pageNum: 1,
            page: 1,
            size: 10,
            total: 0,//总页数
            // currentPage: '',//当前页数
            pagingSize: 10,//分页保留条数
            paging: 1,//分页保留
            limit: 10,
            typeName: '',
            tipText: '',
        }
    },
    created() {
        let InvestigateFlag = this.$getStorage('InvestigateFlag');
        this.jodBoxVisible = InvestigateFlag == 'Y' || InvestigateFlag == 'I' ? true : false;
        this.select = this.$getStorage('productType') ?? 0;
        this.cityText = this.$getStorage('HomeSearchCity') ?? [];
        this.pagingSize = this.$getStorage('fetchDataSize1') ? this.$getStorage('fetchDataSize1') : this.size;
        this.paging = this.$getStorage('fetchDataPage1') ? this.$getStorage('fetchDataPage1') : this.page;
        let params = JSON.parse(this.$UrlEncode.decode(this.$route.query.key));
        this.queryType = params.type ?? '';
        this.typeName = this.$getStorage('typeName') ?? 'firm';
        if (this.typeName == 'firm') {
            this.enterpriseName = this.$getStorage('searchFirmInput');
            this.tipText = this.$getStorage('searchFirmInput');
        } else {
            this.address = this.$getStorage('searchFirmInput') ?? '';
            this.city = this.$getStorage('searchFirmcity') ?? '';
            this.tipText = this.$getStorage('searchFirmInput') ?? '';
        }
        this.$setStorage('isreload', true);
        this.getDate();
    },
    computed: {
        pageSize: {
            get() {
                return this.limit
            },
            set(val) {
                this.limit = val;
            }
        }
    },
    methods: {
        getDate() {
            if (this.queryType == 'text') {
                this.paginationShow = false;
                this.openEnterpriseSearchList(this.paging, this.pagingSize)
            }
        },
        // 获取输入搜索框中的企业列表
        async openEnterpriseSearchList(page = this.page, size = this.size) {
            let that = this,
                enterpriseName = this.enterpriseName,
                address = this.address,
                city = this.city ? this.city.join(" ") : '',
                data = {
                    enterpriseName,
                    address,
                    city: city == '全国' ? 0 : city,
                    page,
                    size,
                };
            await that.$http.openEnterpriseSearchList(data).then(res => {
                if (res.code == 200) {
                    this.searchList = res.data.records;
                    this.currentPage = res.data.current;
                    this.total = res.data.total;
                    this.paginationShow = true;
                }
            })
        },
        // 跳转去供应商页面
        toEnterprise(enterpriseId) {
            if (!this.$getStorage('token')) {
                // this.$emit('showLoginBox')
                this.loginBoxVisible = true
            } else {
                this.$router.push({
                    name: 'SupplierInfoIndex',
                    query: {
                        key: this.$UrlEncode.encode(JSON.stringify({
                            id: enterpriseId,
                            type: 'product',
                        }))
                    }
                })
            }

        },
        CloseLoginBox() {
            this.loginBoxVisible = false;
            if (this.$getStorage('token')) {
                let InvestigateFlag = this.$getStorage('InvestigateFlag');
                this.jodBoxVisible = InvestigateFlag == 'Y' || InvestigateFlag == 'I' ? true : false;
            }
        },
        // 关闭职业弹窗
        CloseJobBox() {
            this.jodBoxVisible = false;
            this.reload();
        },
        handleSizeChange(val) {
            this.currentPage = 1;
            let data = { pageNum: this.currentPage, limit: val, orginLimit: this.limit }
            this.fetchData(data)
        },
        handleCurrentChange(val) {
            let data = { pageNum: val, limit: this.pageSize }
            this.fetchData(data)
        },
        fetchData(e) {
            let page = e ? e.pageNum : (this.$getStorage('fetchDataPage1') ? this.$getStorage('fetchDataPage1') : this.page),
                size = e ? e.limit : (this.$getStorage('fetchDataSize1') ? this.$getStorage('fetchDataSize1') : this.size);
            if (e && e.orginLimit && (e.limit != e.orginLimit)) {
                page = 1
            }
            this.$setStorage('fetchDataPage1', page)
            this.$setStorage('fetchDataSize1', size)
            this.paging = page;//分页保留页数
            this.pagingSize = size;//分页保留分页条数
            this.getDate()
        },
        backPrivious() {
            this.$router.go(-1)
            this.$removeStorage('fetchDataPage1')
            this.$removeStorage('fetchDataSize1')
        },
        handleSearchBox(typeName, inputText, city) {
            let that = this;
            that.enterpriseName = '';
            that.address = '';
            that.city = [];
            this.typeName = typeName;
            if (typeName == 'firm') {
                that.enterpriseName = inputText;
            } else {
                that.address = inputText;
                that.city = city;

            }
            that.getDate();
            that.tipText = inputText;
        }
    },
    beforeDestroy() {
        this.$removeStorage('fetchDataPage')
        this.$removeStorage('fetchDataSize')
    }
}

</script>

<style lang="less" scoped>
.searchResult {
    margin: auto;
    position: relative;

    .search {
        position: relative;

        // position: absolute;
        // right: 0;
        .search-city {
            position: absolute;
            left: 140px;
            width: 190px;
            height: 40px;

            // background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
            /deep/.el-input__inner {
                width: 190px;
                height: 40px;
                background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
                color: #FFFFFF;
            }

            ::placeholder {
                color: #FFFFFF;
            }

            /deep/.el-input__icon {
                color: #FFFFFF;
            }
        }

        .search-options {
            // border: 1px solid red;
            position: absolute;
            top: -30px;
            left: 350px;


            .pitchOn {
                border-bottom: 3px solid #0270F2;
                padding: 5px;
                color: #333333;
                font-size: 14px;
                cursor: pointer;
            }

            .NopitchOn {
                color: #999999;
                font-size: 14px;
                padding: 5px;
                cursor: pointer;
            }
        }

        .search-input {
            position: relative;

            .select-box {
                width: 110px;
                position: absolute;
                left: 0;
            }

            input {
                background-color: #FFF;
                border-radius: 4px;
                border: 1px solid #E0E1E3;
                box-sizing: border-box;
                color: #606266;
                display: inline-block;
                height: 40px;
                line-height: 40px;
                outline: 0;
                padding: 0 50px 0 110px;
                transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
                width: 500px;
            }

            .search-icon {
                width: 50px;
                height: 38px;
                line-height: 40px;
                text-align: center;
                line-height: 40px;
                position: absolute;
                right: 0;
                font-size: 20px;
                border: none;
                outline: none;
                border-left: 1px solid #E0E1E3;
                padding: 0;
                margin: 0;
            }

            .search-icon2 {
                width: 50px;
                height: 38px;
                line-height: 50px;
                position: absolute;
                right: 50px;
                border: none;
                outline: none;
                border-left: 1px solid #E0E1E3;
                padding: 0;
                margin: auto;
            }
        }

        .search-input2 {

            input {
                background-color: #FFF;
                border-radius: 4px;
                border: 1px solid #E0E1E3;
                box-sizing: border-box;
                color: #606266;
                display: inline-block;
                height: 40px;
                line-height: 40px;
                outline: 0;
                padding: 0 50px 0 50px;
                transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
                width: 500px;
            }
        }

        .search-icon3 {
            // border: 1px solid red !important;
            width: 80px;
            border-top-right-radius: 5px !important;
            border-bottom-right-radius: 5px !important;
            color: #FFFFFF;
            background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
        }

        :deep(.el-input__inner) {
            border: 1px solid #E0E1E3;
            border-right: none !important;
        }

        .el-button {
            height: 40px;
            border: 1px solid #E0E1E3;
            border-left: none !important;
            border-radius: 0%;
        }

        el-button {
            border: none;
            margin-left: 5px;
        }
    }

    .search-container {
        position: absolute;
        top: 180px;
        right: 420px;
        z-index: 99;
        border-radius: 10px;

        .input-history {
            // width: 380px;
            border: 1px solid #e7e7e7;
            background-color: #ffffff;
            padding: 10px;
            border-radius: 5px;
            margin-top: 1px;

            .delete-history {
                display: inline-block;
                padding: 1px 15px;
            }

            .history {
                margin-top: 5px;
                padding: 5px;
                flex-wrap: wrap;

                .history-item {
                    // border: 1px solid;
                    background: #ffffff;
                    border-radius: 15px;
                    // padding: 6px 10px;
                    font-size: 14px;
                    // color: #fff;
                    margin: 10px 20px 0 0;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .history-no {
                    padding: 10px;
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }

    .tips {
        font-size: 16px;
        font-family: HarmonyOS Sans SC;
        font-weight: 500;
        color: #999999;
        margin-bottom: 30px;
    }

    .breadcrumb {
        padding-bottom: 30px;
        border-bottom: 1px solid #F8F8F8;

        .el-breadcrumb ::v-deep .el-breadcrumb__inner {
            color: #666666 !important;
            font-weight: 400 !important;
            font-size: 14px;
        }

        .el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
            color: #0363FA !important;
            font-weight: 400 !important;
            font-size: 14px;
        }
    }

    .firmList {
        margin-top: 30px;

        .item {
            padding-top: 10px;
            width: calc(25% - 10px);
            height: 260px;
            background-color: #F6F8FB;
            border-radius: 10px;
            margin-left: 10px;
            margin-bottom: 5px;
            cursor: pointer;

            &:hover {
                transform: translateY(-3px);
            }

            img {
                margin-left: 12px;
                width: 225px;
                height: 170px;
                border-radius: 10px;
            }

            .name {
                margin-top: 20px;
                color: #333333;
                font-size: 14px;
                text-align: center;
                font-weight: 400;
            }

            .city {
                margin-top: 10px;
                color: #333333;
                text-align: center;
                font-size: 14px;
            }
        }

    }

    .pagination {
        width: 100%;

        .el-pagination {
            white-space: pre-wrap;
            margin: 50px;
            align-self: flex-end;
            text-align: right;
            width: 100%;
        }
    }

    // .pagination {
    //     border: 1px solid red;
    //     width: 100%;

    //     .el-pagination {
    //         white-space: pre-wrap;
    //         margin: 50px;
    //         // padding: 20px;
    //         align-self: flex-end;
    //         text-align: right;
    //         width: 100%;
    //     }

    // }

}
</style>